import React from "react";

const WEATHER_ICONS = {
  "01d": ["clear sky"],
  "02d": ["few clouds"],
  "03d": ["scattered clouds"],
  "04d": ["broken clouds", "overcast clouds"],
  "09d": [
    "shower rain",
    "light intensity drizzle",
    "drizzle",
    "heavy intensity drizzle",
    "light intensity drizzle rain",
    "drizzle rain",
    "heavy intensity drizzle rain",
    "shower rain and drizzle",
    "heavy shower rain and drizzle",
    "shower drizzle",
    "light intensity shower rain",
    "shower rain",
    "heavy intensity shower rain",
    "ragged shower rain",
  ],
  "10d": [
    "rain",
    "light rain",
    "moderate rain",
    "heavy intensity rain",
    "very heavy rain",
    "extreme rain",
  ],
  "11d": [
    "thunderstorm",
    "thunderstorm with light rain",
    "thunderstorm with rain",
    "thunderstorm with heavy rain",
    "light thunderstorm",
    "heavy thunderstorm",
    "ragged thunderstorm",
    "thunderstorm with light drizzle",
    "thunderstorm with drizzle",
    "thunderstorm with heavy drizzle",
  ],
  "13d": [
    "light snow",
    "snow",
    "freezing rain",
    "heavy snow",
    "sleet",
    "light shower sleet",
    "shower sleet",
    "light rain and snow",
    "rain and snow",
    "light shower snow",
    "shower snow",
    "heavy shower snow",
  ],
  "50d": [
    "mist",
    "fog",
    "haze",
    "smoke",
    "sand/dust whirls",
    "sand",
    "dust",
    "volcanic ash",
    "squalls",
    "tornado",
  ],
};

const WEATHER_STATUSES = Object.entries(WEATHER_ICONS).reduce(
  (acc, [icon, conditions]) => {
    conditions.forEach((condition) => {
      acc[condition] = icon;
    });
    return acc;
  },
  {}
);

const PRIORITY_WEATHER = [
  "thunderstorm",
  "snow",
  "rain",
  "mist",
  "fog",
  "haze",
];

function determineWeatherKeyAndIcon(data) {
  // Check priority weather conditions first
  const priorityWeather = PRIORITY_WEATHER.find((key) => data[key] === 1);
  if (priorityWeather) {
    return { key: priorityWeather, icon: WEATHER_STATUSES[priorityWeather] };
  }

  // Find the weather condition with the highest count
  return (
    Object.entries(data).reduce((acc, [key, count]) => {
      if (WEATHER_STATUSES[key] && (!acc || count > acc.count)) {
        return { key, count, icon: WEATHER_STATUSES[key] };
      }
      return acc;
    }, null) || null
  );
}

export const WeatherForecast = ({ data }) => {
  const weatherData = determineWeatherKeyAndIcon(data[1]);

  return (
    <div>
      {weatherData && (
        <div>
          <h1 className="Forecast__title">{data[0]}</h1>
          <img
            className="Forecast__weather-icon"
            src={
              "https://openweathermap.org/img/wn/" + weatherData?.icon + ".png"
            }
            alt={weatherData?.key}
          />
          <div className="Forecast__temperature">
            <span className="temperature__max">
              {Math.round(data[1].max)}
              <sup className="temperature__symbol">°</sup>
            </span>
            <span className="temperature__min">
              {Math.round(data[1].min)}
              <sup className="temperature__symbol">°</sup>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
