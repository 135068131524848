import React, { useEffect, useState } from "react";
import { Card, CardContent, makeStyles } from "@material-ui/core";
import CurrentWeather from "./CurrentWeather";
import { WeatherForecast } from "./WeatherForecast";
import weatherWidgetApi from "../api/weatherWidgetApi";
import { getCookie, setCookie } from "../../../utils/cookieFunctions";

const useStyles = makeStyles({
  root: {
    background:
      "linear-gradient(180deg,#005889,#0072b1 56.77%,#6ccbff 141.56%)",
    "& .MuiCardContent-root": {
      padding: "8px !important",
    },
  },
});

const handleData = async (data) => {
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let index = 0;
  let newData = {};
  data.list.forEach((d) => {
    const day = new Date(d.dt * 1000).getDay();
    if (!newData[dayNames[day]]) {
      newData[dayNames[day]] = {
        idx: index,
        icon: d.weather[0].icon,
        min: d.main.temp_min,
        max: d.main.temp_max,
        [d.weather[0].description]: 1,
      };
      index++;
    } else {
      if (newData[dayNames[day]].min > d.main.temp_min) {
        newData[dayNames[day]].min = d.main.temp_min;
      }
      if (newData[dayNames[day]].max < d.main.temp_max) {
        newData[dayNames[day]].max = d.main.temp_max;
      }
    }
    if (newData[dayNames[day]][d.weather[0].description]) {
      newData[dayNames[day]][d.weather[0].description]++;
    } else {
      newData[dayNames[day]][d.weather[0].description] = 1;
    }
  });
  const sortedWeatherData = Object.entries(newData).sort(
    ([, a], [, b]) => a.idx - b.idx
  );

  return sortedWeatherData;
};

const WeatherWidget = () => {
  const classes = useStyles();
  const [forecastData, setForecastData] = useState();
  const [weatherData, setWeatherData] = useState();

  const fetchData = async () => {
    const forecast = getCookie("forecastData");
    const weather = getCookie("weatherData");
    if (forecast && weather) {
      setForecastData(forecast);
      setWeatherData(weather);
      console.log("using existing data");
    } else {
      try {
        const res = await weatherWidgetApi();
        const sortedData = await handleData(res.data.forecastdata);
        setForecastData(sortedData);
        setWeatherData(res.data.weatherdata);
        setCookie({
          name: "weatherData",
          fetchedon: res.fetchedOn.UNIX,
          value: res.data.weatherdata,
          minutes: 60,
        });
        setCookie({
          name: "forecastData",
          fetchedon: res.fetchedOn.UNIX,
          value: sortedData,
          minutes: 60,
        });
        console.log("Fetched new data");
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className="WeatherAndForecast">
          {weatherData && <CurrentWeather data={weatherData} />}
          <div className="WeatherAndForecast__container">
            {forecastData &&
              forecastData.map((data, index) => (
                <WeatherForecast key={index} data={data} />
              ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default WeatherWidget;
